import { default as indexiLDUNLQ0YUMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/account/custom/index.vue?macro=true";
import { default as packagesj9kML2XSHpMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/account/custom/packages.vue?macro=true";
import { default as partsVDJ1HHc4n3Meta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/account/custom/parts.vue?macro=true";
import { default as customBSimkTLAXRMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/account/custom.vue?macro=true";
import { default as indexuU6jFlOEpXMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/account/index.vue?macro=true";
import { default as ratesAaLnrWok0RMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/account/rates.vue?macro=true";
import { default as subscriptionDoAiwoOS2vMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/account/subscription.vue?macro=true";
import { default as accountgr6yTGVAEOMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/account.vue?macro=true";
import { default as _91id_93eMDO3XCsxJMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/activite/clients/edit/[id].vue?macro=true";
import { default as indexk34ny0g0GeMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/activite/clients/index.vue?macro=true";
import { default as _91id_93UHNCwt33LmMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/activite/clients/show/[id].vue?macro=true";
import { default as _91id_93r3xA3I1oIXMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/activite/commandes/[id].vue?macro=true";
import { default as indexMtsoKOoqKYMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/activite/commandes/index.vue?macro=true";
import { default as vehiculeswTU8SvroEtMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/activite/vehicules.vue?macro=true";
import { default as _91title_93_45_91id_93Mu8C4kiuiLMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/Boutique/[title]-[id].vue?macro=true";
import { default as indexw2NMRuFMKdMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/Boutique/index.vue?macro=true";
import { default as productsPHkfHWwTaIMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/Boutique/products.vue?macro=true";
import { default as indexKAtX0rj0RdMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/catalog/index.vue?macro=true";
import { default as configurationy72XjF9Vo9Meta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/catalog/maintenance/configuration.vue?macro=true";
import { default as indexasPEsjxnmHMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/catalog/maintenance/index.vue?macro=true";
import { default as planOfNQ5tLHneMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/catalog/maintenance/plan.vue?macro=true";
import { default as worksKRcJ9K5fNKMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/catalog/maintenance/works.vue?macro=true";
import { default as maintenancenCvM7YSO5iMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/catalog/maintenance.vue?macro=true";
import { default as manualsRMOGVXnrE4Meta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/catalog/manuals.vue?macro=true";
import { default as timesep1LpW6uFRMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/catalog/times.vue?macro=true";
import { default as catalogild2myZ1cCMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/catalog.vue?macro=true";
import { default as commandeMVtbneeu4UMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/commande.vue?macro=true";
import { default as _91tab_938q5OiaEMU0Meta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/devis/[tab].vue?macro=true";
import { default as _91id_93CtmdTtek4AMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/devis/edit/[id].vue?macro=true";
import { default as _91id_93Kbl5hU3wSaMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/devis/show/[id].vue?macro=true";
import { default as imprimer_45pdf65y5ECdiFqMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/imprimer-pdf.vue?macro=true";
import { default as index6jTbIooIAIMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/index.vue?macro=true";
import { default as loginZdvs496Ue3Meta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/login.vue?macro=true";
import { default as panieruBBqC84Vm6Meta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/panier.vue?macro=true";
import { default as indexKObWeJUhfwMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/pneumatique/index.vue?macro=true";
import { default as productskzz7IK0H6iMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/pneumatique/products.vue?macro=true";
import { default as pneumatique5zI9bdSdcTMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/pneumatique.vue?macro=true";
import { default as maintenancekZAQPmFtrwMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/print/maintenance.vue?macro=true";
import { default as productsjx7DmauiffMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/products.vue?macro=true";
import { default as signupSodOFB2m4TMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/signup.vue?macro=true";
import { default as tableau_45de_45bordTI3PjycTFsMeta } from "/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/tableau-de-bord.vue?macro=true";
export default [
  {
    name: accountgr6yTGVAEOMeta?.name,
    path: "/account",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/account.vue"),
    children: [
  {
    name: customBSimkTLAXRMeta?.name,
    path: "custom",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/account/custom.vue"),
    children: [
  {
    name: "account-custom",
    path: "",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/account/custom/index.vue")
  },
  {
    name: "account-custom-packages",
    path: "packages",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/account/custom/packages.vue")
  },
  {
    name: "account-custom-parts",
    path: "parts",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/account/custom/parts.vue")
  }
]
  },
  {
    name: "account",
    path: "",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/account/index.vue")
  },
  {
    name: "account-rates",
    path: "rates",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/account/rates.vue")
  },
  {
    name: "account-subscription",
    path: "subscription",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/account/subscription.vue")
  }
]
  },
  {
    name: "activite-clients-edit-id",
    path: "/activite/clients/edit/:id()",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/activite/clients/edit/[id].vue")
  },
  {
    name: "activite-clients",
    path: "/activite/clients",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/activite/clients/index.vue")
  },
  {
    name: "activite-clients-show-id",
    path: "/activite/clients/show/:id()",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/activite/clients/show/[id].vue")
  },
  {
    name: "activite-commandes-id",
    path: "/activite/commandes/:id()",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/activite/commandes/[id].vue")
  },
  {
    name: "activite-commandes",
    path: "/activite/commandes",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/activite/commandes/index.vue")
  },
  {
    name: "activite-vehicules",
    path: "/activite/vehicules",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/activite/vehicules.vue")
  },
  {
    name: "Boutique-title-id",
    path: "/Boutique/:title()-:id()",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/Boutique/[title]-[id].vue")
  },
  {
    name: "Boutique",
    path: "/Boutique",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/Boutique/index.vue")
  },
  {
    name: "Boutique-products",
    path: "/Boutique/products",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/Boutique/products.vue")
  },
  {
    name: catalogild2myZ1cCMeta?.name,
    path: "/catalog",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/catalog.vue"),
    children: [
  {
    name: "catalog",
    path: "",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/catalog/index.vue")
  },
  {
    name: maintenancenCvM7YSO5iMeta?.name,
    path: "maintenance",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/catalog/maintenance.vue"),
    children: [
  {
    name: "catalog-maintenance-configuration",
    path: "configuration",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/catalog/maintenance/configuration.vue")
  },
  {
    name: "catalog-maintenance",
    path: "",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/catalog/maintenance/index.vue")
  },
  {
    name: "catalog-maintenance-plan",
    path: "plan",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/catalog/maintenance/plan.vue")
  },
  {
    name: "catalog-maintenance-works",
    path: "works",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/catalog/maintenance/works.vue")
  }
]
  },
  {
    name: "catalog-manuals",
    path: "manuals",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/catalog/manuals.vue")
  },
  {
    name: "catalog-times",
    path: "times",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/catalog/times.vue")
  }
]
  },
  {
    name: "commande",
    path: "/commande",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/commande.vue")
  },
  {
    name: "devis-tab",
    path: "/devis/:tab()",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/devis/[tab].vue")
  },
  {
    name: "devis-edit-id",
    path: "/devis/edit/:id()",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/devis/edit/[id].vue")
  },
  {
    name: "devis-show-id",
    path: "/devis/show/:id()",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/devis/show/[id].vue")
  },
  {
    name: "imprimer-pdf",
    path: "/imprimer-pdf",
    meta: imprimer_45pdf65y5ECdiFqMeta || {},
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/imprimer-pdf.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginZdvs496Ue3Meta || {},
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/login.vue")
  },
  {
    name: "panier",
    path: "/panier",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/panier.vue")
  },
  {
    name: pneumatique5zI9bdSdcTMeta?.name,
    path: "/pneumatique",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/pneumatique.vue"),
    children: [
  {
    name: "pneumatique",
    path: "",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/pneumatique/index.vue")
  },
  {
    name: "pneumatique-products",
    path: "products",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/pneumatique/products.vue")
  }
]
  },
  {
    name: "print-maintenance",
    path: "/print/maintenance",
    meta: maintenancekZAQPmFtrwMeta || {},
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/print/maintenance.vue")
  },
  {
    name: "products",
    path: "/products",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/products.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: signupSodOFB2m4TMeta || {},
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/signup.vue")
  },
  {
    name: "tableau-de-bord",
    path: "/tableau-de-bord",
    component: () => import("/var/www/vhosts/eitedelinternational.com/app.eitedelinternational.com/pages/tableau-de-bord.vue")
  }
]